// import { useInView } from 'react-intersection-observer';

export default function AnimatedComponent({ children }) {
  // const [ref, inView] = useInView({
  //   triggerOnce: false, // Animates only once when in view
  //   threshold: 0.2, // Adjust as needed
  // });

  // return (
  //   <div ref={ref} className={inView ? 'animate-in' : 'animate-out'}>
  //     {children}
  //   </div>
  // );

  // Note that I have commented out the above due to minor bug on mobile devices where you had to scroll for things to appear.
  return (
    <div>
      {children}
    </div>
  );
}
