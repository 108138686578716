import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ContactTutorForm from './contactTutorForm';
import { Col, Row } from 'react-bootstrap';
import ContactTutorProfile from './contactTutorProfile';

export default function ContactTutorModal(props) {

    function submitContactForm() {
        props.onHide();
    }

    ContactTutorModal.submitContactForm = submitContactForm;

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Contact {props.tutor.firstName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <ContactTutorProfile tutor={props.tutor} />
                        </Col>
                        <Col>
                            <ContactTutorForm />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button form='contact-tutor-form' variant='outline-dark' type="submit">
                        Submit
                    </Button>
                    <Button onClick={props.onHide} variant='outline-dark'>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}