import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import NewTutorForm from './newTutorForm';
import { useRef, useState } from "react";


export default function NewTutorModal(props) {

    const firstNameRef = useRef(null);
    const surnameRef = useRef(null);
    const personalEmailRef = useRef(null);
    const picRef = useRef(null);
    const uniNameRef = useRef(null);
    const descriptionRef = useRef(null);
    const degreeRef = useRef(null);
    const [primarySubjects, setPrimarySubjects] = useState("Does not tutor at Primary Level");
    const [gcseSubjects, setGcseSubjects] = useState("Does not tutor at GCSE Level");
    const [alevelSubjects, setAlevelSubjects] = useState("Does not tutor at A-Level");
    const educationRef = useRef(null);
    const hobbiesRef = useRef(null);

    const [confirmModalShow, setConfirmModalShow] = useState(false);

    async function addNewTutor(ev) {
        ev.preventDefault();

        const firstName = firstNameRef.current.value;
        const surname = surnameRef.current.value;
        const personalEmail = personalEmailRef.current.value;
        const profilePic = picRef.current.files[0];
        const uniName = uniNameRef.current.value;
        const summary = descriptionRef.current.value;
        const degree = degreeRef.current.value;
        const otherEducation = educationRef.current.value;

        const data = new FormData();
        data.set('firstName', firstName);
        data.set('surname', surname);
        data.set('personalEmail', personalEmail);
        data.set('profilePic', profilePic);
        data.set('uniName', uniName);
        data.set('summary', summary);
        data.set('degree', degree);
        data.set('otherEducation', otherEducation);
        data.set('primarySubjects', primarySubjects);
        data.set('gcseSubjects', gcseSubjects);
        data.set('alevelSubjects', alevelSubjects);

        const response = await fetch('https://sore-pear-dove-kilt.cyclic.app/tutoring', {
            method: 'POST',
            body: data,
        });



        if (response.status === 400) {
            console.log('Error');
        } else {
            console.log('Success');
            setConfirmModalShow(true);
        }

        props.updateTutorCards();

        props.onHide();
    }

    NewTutorModal.addNewTutor = addNewTutor;

    return (
        <>

            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add a New Tutor Profile
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewTutorForm
                        firstNameRef={firstNameRef}
                        surnameRef={surnameRef}
                        personalEmailRef={personalEmailRef}
                        picRef={picRef}
                        uniNameRef={uniNameRef}
                        descriptionRef={descriptionRef}
                        degreeRef={degreeRef}
                        setPrimarySubjects={setPrimarySubjects}
                        setGcseSubjects={setGcseSubjects}
                        setAlevelSubjects={setAlevelSubjects}
                        educationRef={educationRef}
                        hobbiesRef={hobbiesRef}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button form='tutor-form' variant='outline-dark' type="submit">
                        Submit
                    </Button>
                    <Button onClick={props.onHide} variant='outline-dark'>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={confirmModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        New Tutor Profile Request Sent
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Request Sent - Awaiting Approval</h4>
                    <p>
                        Your request to create a tutor profile has been sent. You will be able to see it on the page once it has been approved.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setConfirmModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}