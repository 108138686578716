import OxfordImage from "../images/oxford.webp"
// import CarouselSlides from "../components/AboutUs/carouselslides";
import { Row, Col, Image, Button } from "react-bootstrap";
import AnimatedComponent from "../components/AboutUs/animatedcomponent"

export default function AboutUs() {
    // const tutors = [mushy, ishwinder, jama, ishwinder, mushy, ishwinder, jama]


    return (
        <div>
            <AnimatedComponent>
                {/* <CarouselSlides /> */}
                <div style={{ position: "relative" }}>
                    <Image src={OxfordImage} fluid style={{ width: "100%", height: "20%" }} />
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                            padding: "10px",
                            borderRadius: "5px",
                            maxWidth: "80%",
                        }}
                    >
                        <h1 className="display-1"
                            style={{
                                fontSize: "2rem", // Increase font size for a bold look
                                fontWeight: "bold", // Already set to bold
                                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)", // Add a subtle shadow
                                fontFamily: "Arial, sans-serif", // Use a common font-family
                                letterSpacing: "1px", // Add some letter spacing for a modern feel
                                color: "white"
                            }}
                        >
                            Welcome to Excellalearn
                        </h1>
                    </div>
                </div>
            </AnimatedComponent>

            <AnimatedComponent>
                <Row className="align-items-center" style={{ height: "60%", padding: 30 }}>
                    <Col >
                        <h4 className="display-4" style={{ fontSize: "2rem", letterSpacing: "0.5px", fontWeight: "bold" }}>Who are we?</h4>
                        <p>Excellalearn is a premier online academic platform dedicated to providing high-quality, personalized educational support to students around the world. Our mission is to foster academic excellence by connecting students with experienced and passionate tutors who can cater to their individual learning needs. Excellalearn also provides free, in-depth videos on multiple subjects through our YouTube channel helping ensure any student in the world can get access to high-quality education.</p>
                        <p>Founded on the principles of accessibility, flexibility, and expertise, Excellalearn offers a wide range of tutoring services across various subjects and educational levels. Our tutors are carefully vetted to ensure they possess the necessary qualifications, experience, and teaching skills to help students succeed. All our tutors come from the world's very best universities and have studied some of the most competitive courses at those universities (We do not take on tutors with mickey mouse degrees simply because they come from an elite unviersity). This is unlike many other tutoring companies where often tutors are not chosen through any rigorous process.</p>
                        <p>We believe in the power of personalized education and strive to create an engaging and supportive learning environment for every student. Whether you need help with homework, exam preparation, or mastering a new subject, Excellalearn is here to help you achieve your academic goals.</p>
                        <p>Join the Excellalearn community today and experience the difference that personalized, one-on-one tutoring with the most elite tutors can make in your educational journey.</p>
                        <p>Please note that Excellalearn is a brand new company, as we grow we expect our impact and goals to grow beyond just tutoring and online videos.</p>
                        <div className="d-flex justify-content-center">
                            <Button variant="outline-dark" href="/tutoring" style={{ margin: 10 }}>Find Tutors Here</Button>
                        </div>
                    </Col>
                </Row>
            </AnimatedComponent>

            {/* <AnimatedComponent>
                <Row className="align-items-center" style={{ height: "60%", padding: 30, backgroundColor: "rgba(250, 250, 250, 0.7)" }}>
                    <Col >
                        <h4 className="display-4" style={{ fontSize: "2rem", letterSpacing: "0.5px", fontWeight: "bold" }}>Our Services</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <Container>
                            <Row>
                                <Col md={5}><ServiceCard service="Tutoring" /></Col>
                            </Row>
                            <Row>
                                <Col md={{ span: 5, offset: 7 }}><ServiceCard service="Uni Admissions Support" /></Col>
                            </Row>
                            <Row>
                                <Col md={5}><ServiceCard service="Free GCSE & A-Level Videos" /></Col>
                            </Row>
                            <Row>
                                <Col md={{ span: 5, offset: 7 }}><ServiceCard service="School Visits" /></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </AnimatedComponent> */}

            {/* <AnimatedComponent>
                <h4 className="display-4" style={{ fontSize: "2rem", letterSpacing: "0.5px", fontWeight: "bold", padding: 30 }}>Meet some of our team</h4>

                <div style={{ overflowX: 'auto', paddingLeft: 30, paddingBottom: 30 }}>
                    <div style={{ display: 'flex' }}>
                        {tutors.map((tutor) => {
                            return (
                                <TutorCard tutor={tutor} />
                            );
                        })}
                    </div>
                </div>
            </AnimatedComponent> */}


        </div>
    );
}