import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import '../cards.css'
import ContactTutorModal from './contactTutorModal';
import TutorProfileModal from './tutorProfileModal';

export default function TutorCards({ tutors }) {

    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(true);
        return () => {
            setShow(false);
        }
    }, []);

    const [showContactModal, setShowContactModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [selectedTutor, setSelectedTutor] = useState(null);

    function handleContactMeClick(tutor) {
        setSelectedTutor(tutor);
        setShowContactModal(true);
    }

    function handleProfileClick(tutor) {
        setSelectedTutor(tutor);
        setShowProfileModal(true);
    }

    function handleBookSessionClick(tutor) {
        setSelectedTutor(tutor);
        const calendlyUrl = tutor.calendlyURL;
        window.open(calendlyUrl, '_blank');
    }

    const approvedTutors = tutors.filter(tutor => tutor.approved === true);

    // const tutorImages = []
    return (
        <>
            <Row xs={1} sm={1} md={3} className={`g-4 ${show ? 'fade-in' : ''}`} style={{ paddingBottom: 80, paddingTop: 20 }}>
                {approvedTutors.map((tutor, index) => (
                    <Col key={index}>
                        <Card className={`h-100 ${show ? 'fade-in' : ''}`}>
                            <Card.Img variant="top" height="300" src={tutor.profilePicSquareUrl} />
                            <Card.Body>
                                <Card.Title>{tutor.firstName}</Card.Title>
                                <Card.Subtitle>{tutor.uniName}</Card.Subtitle>
                                <Card.Text>
                                    {tutor.degree}
                                </Card.Text>
                                <Card.Text>
                                    £30 per hour
                                </Card.Text>
                                <Card.Text>
                                    Primary School Level Subjects Taught: {tutor.primarySubjects}
                                </Card.Text>
                                <Card.Text>
                                    GCSE Level Subjects Taught: {tutor.gcseSubjects}
                                </Card.Text>
                                <Card.Text>
                                    A-Level Subjects Taught: {tutor.alevelSubjects}
                                </Card.Text>
                                <Button variant='outline-dark' onClick={() => handleBookSessionClick(tutor)} style={{ margin: 5 }}>Book Session</Button>
                                <Button variant='outline-dark' onClick={() => handleContactMeClick(tutor)} style={{ margin: 5 }}>Contact {tutor.firstName}</Button>
                                <Button variant='outline-dark' onClick={() => handleProfileClick(tutor)} style={{ margin: 5 }}>View Full Tutor Profile</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            {selectedTutor &&
                <ContactTutorModal
                    onHide={() => {
                        setShowContactModal(false);
                    }}
                    show={showContactModal}
                    tutor={selectedTutor}
                />}

            {selectedTutor &&
                <TutorProfileModal
                    onHide={() => {
                        setShowProfileModal(false);
                    }}
                    show={showProfileModal}
                    tutor={selectedTutor}
                />}

        </>
    );
}