import Modal from 'react-bootstrap/Modal';
import FullTutorProfile from './fullTutorProfile';
export default function TutorProfileModal(props) {
    return (
        <>
            <Modal {...props} fullscreen={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.tutor.firstName}'s Full Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FullTutorProfile tutor={props.tutor} />
                </Modal.Body>
            </Modal>
        </>
    )
}