import { Route, Routes } from 'react-router-dom'
import AboutUs from './pages/aboutus'
import Layout from './layout';
import Tutoring from './pages/tutoring';

export default function Router() {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<AboutUs />} />
                {/* <Route path='/uniadmissions' element={<UniAdmissions />} /> */}
                {/* <Route path='/uniadmissions/entrance-exams' element={<EntranceExams />} /> */}
                {/* <Route path='/uniadmissions/interviews' />
                <Route path='/uniadmissions/personal-statement' /> */}
                {/* <Route path='/mentoring' element={<Mentoring />} /> */}
                <Route path='/tutoring' element={<Tutoring />} />
                {/* <Route path='/contactus' element={<Contact />} /> */}
            </Route>
        </Routes>
    );
}