import { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import ContactTutorModal from "./contactTutorModal";
import emailjs from '@emailjs/browser';

export default function ContactTutorForm() {

    const [validated, setValidated] = useState(false);

    const contactForm = useRef();

    function handleSubmit(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return;
        }

        event.preventDefault();

        emailjs.sendForm('service_gqsdp9q', 'contact_form', contactForm.current, 'zh_QxYm9Qs51M8tYj')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        ContactTutorModal.submitContactForm();
    }

    return (
        <Form ref={contactForm} id='contact-tutor-form' noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" >
                <Form.Label>First Name:</Form.Label>
                <Form.Control name="first_name" required placeholder="Enter first name" />
                <Form.Control.Feedback type="invalid">
                    Please enter your first name.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" >
                <Form.Label>Surname:</Form.Label>
                <Form.Control name="surname" required placeholder="Enter surname" />
                <Form.Control.Feedback type="invalid">
                    Please enter your surname.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" >
                <Form.Label>Email Address:</Form.Label>
                <Form.Control type="email" name="user_email" required placeholder="Enter email" />
                <Form.Text muted>Please make sure this is correct otherwise the tutor will not be able to contact you</Form.Text>
                <Form.Control.Feedback type="invalid">
                    Please enter your email address. The tutor will contact you through this.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3' >
                <Form.Label>Message:</Form.Label>
                <Form.Control name="message" required as="textarea" rows={5} placeholder='Enter whatever you would like to say to the tutor here... if you want to setup a tutoring session or have any questions for the tutor this is the place.. also feel free to add any other relevant information...' />
                <Form.Control.Feedback type="invalid">
                    Please enter your message for the tutor.
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    )
}