import { Container, Row, Col } from "react-bootstrap"
import NewTutorModal from "../components/Tutoring/newTutorModal";
import { useState, useEffect, useCallback } from "react";
import TutorCards from "../components/Tutoring/tutorCards";
import { MDBSpinner } from 'mdb-react-ui-kit';


export default function Tutoring() {

    // const LOCALURL = "http://localhost:4000/tutoring"
    // const REMOTEURL = "https://tutoringbackend.onrender.com/tutoring"
    // const REMOTEURL = "https://93v18x2mq5.execute-api.us-east-1.amazonaws.com/prod/tutoring"
    // const REMOTEURL = "https://tutoringbackend-swart.vercel.app/api/getTutors.js/"
    const APIURL = process.env.REACT_APP_API_URL

    const [modalShow, setModalShow] = useState(false);
    const [tutors, setTutors] = useState([]);
    const [spinnerShow, setSpinnerShow] = useState(true);

    const updateTutorCards = useCallback(() => {
        fetch(APIURL + "/api/getTutors.js").then(res => {
            res.json().then(tut => {
                setSpinnerShow(false);
                setTutors(tut);
            });
        });
    }, [APIURL]);

    useEffect(() => {
        updateTutorCards();
    }, [updateTutorCards]);

    return (
        <Container className="pt-5">
            <Row className="align-items-center justify-content-center" style={{ height: "100%" }}>
                <Col lg={8}>
                    <h1 className="display-4">Tutoring</h1>
                    <p className="lead">Get personal, one-to-one tutoring with one of our tutors</p>
                    <p>Whether you're struggling with a specific subject or looking to excel in your studies, our tutors are here to help. Our tutoring service offers a tailored approach to meet your individual learning needs.</p>
                    <h3>How It Works</h3>
                    <ul>
                        <li><strong>Find a Tutor</strong>: Browse through our list of tutors below and choose the one that best fits your needs.</li>
                        <li><strong>View Full Profile</strong>: Learn more about your tutor's qualifications, experience, and teaching style by viewing their detailed profile.</li>
                        <li><strong>Contact Your Tutor</strong>: If you have any questions before booking you can easily get in touch with the tutor directly through the contact button on their profile. This will send an email to the tutor with your question. Please ensure to enter your own details correctly so the tutor is able to respond to you.</li>
                        <li><strong>Book a Lesson</strong>: Schedule a lesson at a time that is convenient for you. Our tutors are flexible and available to accommodate your schedule. When booking, please ensure to enter your email and phone number accurately so the tutor can contact you before the lesson. As soon as you book a lesson you will receive an email containing the link for the meeting. Our lessons take place through Google Meet.</li>


                    </ul>
                    <h3>Payment</h3>
                    <p>For now, there is no payment required when making the initial booking of a lesson. However please note that payment is required before the start of the actual lesson itself, the tutor will directly contact you through whatsapp and/or email with their details. If no payment is made before the start of the lesson, then no lesson will take place. </p>
                </Col>
            </Row>

            {/* <Row className="justify-content-center">
                <Col lg={8} className="text-center">
                    <Button
                        variant="outline-dark"
                        style={{ margin: 10 }}
                        onClick={() => setModalShow(true)}
                    >
                        Add New Tutor Profile
                    </Button>
                </Col>
            </Row> */}


            <NewTutorModal show={modalShow} onHide={() => setModalShow(false)} updateTutorCards={updateTutorCards} />


            {spinnerShow && (
                <div className='d-flex align-items-center' style={{ justifyContent: "center", paddingLeft: 150, paddingRight: 150, paddingTop: 50 }}>
                    <strong>Loading Tutor Profiles...</strong>
                    <MDBSpinner className='ms-auto' role='status' />
                </div>
            )}

            <TutorCards tutors={tutors} />

        </Container>
    );
}