import { Container, Image } from "react-bootstrap";

export default function ContactTutorProfile({ tutor }) {
    return (
        <Container>
            <Image src={tutor.profilePicSquareUrl} rounded fluid />
            <p style={{ paddingTop: 15 }}>
                {tutor.description}
            </p>
            <hr />
            <text style={{ fontWeight: "bold" }}>
                Subjects Taught (Primary School Level):
            </text>
            <p>
                {tutor.primarySubjects}
            </p>
            <text style={{ fontWeight: "bold" }}>
                Subjects Taught (GCSE Level):
            </text>
            <p>
                {tutor.gcseSubjects}
            </p>
            <text style={{ fontWeight: "bold" }}>
                Subjects Taught (A-Level):
            </text>
            <p>
                {tutor.alevelSubjects}
            </p>
        </Container>
    );
}