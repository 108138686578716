import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import NewTutorModal from './newTutorModal';

export default function NewTutorForm({ firstNameRef, surnameRef, personalEmailRef, picRef, uniNameRef, descriptionRef, degreeRef, setPrimarySubjects, setGcseSubjects, setAlevelSubjects, educationRef, hobbiesRef }) {

    const [validated, setValidated] = useState(false);

    async function handleSubmit(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return;
        }

        await setPrimarySubjects([...form.querySelectorAll('input[name="primarySubjects"]:checked')].map(input => input.value).join(", "));
        await setGcseSubjects([...form.querySelectorAll('input[name="gcseSubjects"]:checked')].map(input => input.value).join(", "));
        await setAlevelSubjects([...form.querySelectorAll('input[name="alevelSubjects"]:checked')].map(input => input.value).join(", "));

        NewTutorModal.addNewTutor(event);
    }

    return (
        <Form id='tutor-form' noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicFirstName">
                <Form.Label>First Name:</Form.Label>
                <Form.Control required ref={firstNameRef} placeholder="Enter first name" />
                <Form.Control.Feedback type="invalid">
                    Please enter your first name.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicSurname">
                <Form.Label>Surname:</Form.Label>
                <Form.Control required ref={surnameRef} placeholder="Enter surname" />
                <Form.Control.Feedback type="invalid">
                    Please enter your surname.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Personal Email:</Form.Label>
                <Form.Control required ref={personalEmailRef} placeholder="Enter email" />
                <Form.Control.Feedback type="invalid">
                    Please enter your email.
                </Form.Control.Feedback>
                <Form.Text muted>Note: Your personal email will not be shared with students. This is for us to contact you.</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicImage">
                <Form.Label>Profile Picture:</Form.Label>
                <Form.Control required ref={picRef} type="file" />
                <Form.Control.Feedback type="invalid">
                    Please select a profile picture to use.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicUniName">
                <Form.Label>University Name:</Form.Label>
                <Form.Select required ref={uniNameRef} aria-label="Default select example">
                    <option value="">Select your university</option>
                    <option value="Oxford">Oxford</option>
                    <option value="Cambridge">Cambridge</option>
                    <option value="Imperial">Imperial</option>
                    <option value="LSE">LSE</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    Please select a university
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDegree">
                <Form.Label>Degree:</Form.Label>
                <Form.Control required ref={degreeRef} placeholder="Enter degree" />
                <Form.Control.Feedback type="invalid">
                    Please enter your degree.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label style={{ marginRight: 10 }}>Subjects Taught (Primary School Level):</Form.Label>
                <Form.Check inline label="Maths" name='primarySubjects' value="Maths" />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label style={{ marginRight: 10 }}>Subjects Taught (GCSE Level):</Form.Label>
                <Form.Check inline label="Maths" name='gcseSubjects' value="Maths" />
                <Form.Check inline label="Computer Science" name='gcseSubjects' value="Computer Science" />
                <Form.Check inline label="Chemistry" name='gcseSubjects' value="Chemistry" />
                <Form.Check inline label="Physics" name='gcseSubjects' value="Physics" />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label style={{ marginRight: 10 }}>Subjects Taught (A-Level):</Form.Label>
                <Form.Check inline label="Maths" name='alevelSubjects' value="Maths" />
                <Form.Check inline label="Further Maths" name='alevelSubjects' value="Further Maths" />
                <Form.Check inline label="Chemistry" name='alevelSubjects' value="Chemistry" />
                <Form.Check inline label="Computer Science" name='alevelSubjects' value="Computer Science" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEducation">
                <Form.Label>Other Education:</Form.Label>
                <Form.Control required ref={educationRef} as="textarea" rows={5} placeholder="Enter A-Levels, GCSEs and anything else here..." />
                <Form.Control.Feedback type="invalid">
                    Please enter your high school education results.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Hobbies:</Form.Label>
                <Form.Control required ref={hobbiesRef} as="textarea" rows={5} placeholder="Enter your hobbies or other things you enjoy doing..." />
                <Form.Control.Feedback type="invalid">
                    Please enter some of your hobbies. This will allow us to match you with a student with similar interests.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicDescription'>
                <Form.Label>Tutor Summary:</Form.Label>
                <Form.Control required ref={descriptionRef} as="textarea" rows={5} placeholder='Summarize your teaching style, personality and anything else that would be useful...' />
                <Form.Control.Feedback type="invalid">
                    Please enter a summary.
                </Form.Control.Feedback>
            </Form.Group>

        </Form>
    );
}