import React from 'react';
import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBCardText,
    MDBCardBody,
    MDBCardImage,
    MDBListGroup,
    MDBListGroupItem
} from 'mdb-react-ui-kit';

export default function FullTutorProfile(props) {

    return (
        <MDBContainer>
            <MDBRow>
                <MDBCard className="mb-4">
                    <MDBCardBody className="text-center">
                        <MDBCardImage
                            src={props.tutor.profilePicCircleUrl}
                            alt="avatar"
                            className="rounded-circle"
                            style={{ width: '150px' }}
                            fluid />
                        <p className="text-muted mb-4" style={{ marginTop: 10 }}>{props.tutor.firstName}</p>
                        <p className="text-muted mb-4" style={{ marginTop: -20, whiteSpace: 'pre-wrap' }}>{props.tutor.summary}</p>
                    </MDBCardBody>
                </MDBCard>

                <MDBCard className="mb-4">
                    <MDBCardBody>
                        <MDBListGroup className="p-0 text-center">
                            <MDBListGroupItem>
                                <MDBRow>
                                    <MDBCol sm="3">
                                        <MDBCardText>First Name</MDBCardText>
                                    </MDBCol>
                                    <MDBCol sm="9">
                                        <MDBCardText className="text-muted">{props.tutor.firstName}</MDBCardText>
                                    </MDBCol>
                                </MDBRow>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                <MDBRow>
                                    <MDBCol sm="3">
                                        <MDBCardText>Email</MDBCardText>
                                    </MDBCol>
                                    <MDBCol sm="9">
                                        <MDBCardText className="text-muted">{props.tutor.personalEmail}</MDBCardText>
                                    </MDBCol>
                                </MDBRow>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                <MDBRow>
                                    <MDBCol sm="3">
                                        <MDBCardText>University</MDBCardText>
                                    </MDBCol>
                                    <MDBCol sm="9">
                                        <MDBCardText className="text-muted">{props.tutor.uniName}</MDBCardText>
                                    </MDBCol>
                                </MDBRow>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                <MDBRow>
                                    <MDBCol sm="3">
                                        <MDBCardText>Degree</MDBCardText>
                                    </MDBCol>
                                    <MDBCol sm="9">
                                        <MDBCardText className="text-muted">{props.tutor.degree}</MDBCardText>
                                    </MDBCol>
                                </MDBRow>
                            </MDBListGroupItem>
                        </MDBListGroup>
                    </MDBCardBody>
                </MDBCard>

                <MDBCard className="mb-4">
                    <MDBCardBody className="p-0 text-center">
                        <MDBCardText style={{ fontWeight: "bolder", marginTop: 20 }}>Subjects Taught:</MDBCardText>
                        <MDBListGroup flush className="rounded-3">
                            <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                                <MDBRow className="w-100 align-items-center">
                                    <MDBCol sm="3">
                                        <MDBCardText>Primary School</MDBCardText>
                                    </MDBCol>
                                    <MDBCol sm="9">
                                        <MDBCardText className="text-muted">{props.tutor.primarySubjects}</MDBCardText>
                                    </MDBCol>
                                </MDBRow>
                            </MDBListGroupItem>
                            <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                                <MDBRow className="w-100 align-items-center">
                                    <MDBCol sm="3">
                                        <MDBCardText>GCSEs</MDBCardText>
                                    </MDBCol>
                                    <MDBCol sm="9">
                                        <MDBCardText className='text-muted'>{props.tutor.gcseSubjects}</MDBCardText>
                                    </MDBCol>
                                </MDBRow>
                            </MDBListGroupItem>
                            <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                                <MDBRow className="w-100 align-items-center">
                                    <MDBCol sm="3">
                                        <MDBCardText>A-Levels</MDBCardText>
                                    </MDBCol>
                                    <MDBCol sm="9">
                                        <MDBCardText className='text-muted'>{props.tutor.alevelSubjects}</MDBCardText>
                                    </MDBCol>

                                </MDBRow>
                            </MDBListGroupItem>
                        </MDBListGroup>
                    </MDBCardBody>
                </MDBCard>

                <MDBRow>
                    <MDBCard className="mb-4 mb-md-0">
                        <MDBCardBody className='p-0 text-center'>
                            <MDBCardText style={{ fontWeight: "bolder", marginTop: 20 }}>Education:</MDBCardText>
                            <MDBRow style={{ padding: 5 }}>
                                <MDBCardText className="text-muted" style={{ whiteSpace: 'pre-wrap' }}>{props.tutor.otherEducation}</MDBCardText>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBRow>

            </MDBRow>
        </MDBContainer>
    );
}