import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavDropdown } from 'react-bootstrap';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../../images/excellalearnlogo.jpeg';


export default function NavigationBar() {

    const pages = ['About Us', 'Tutoring']
    const links = ['/', '/tutoring']

    const [show, setShow] = useState(false);
    const hideTimeoutRef = useRef(null);
    const location = useLocation();


    const isActive = (path) => {
        return location.pathname === path;
    };

    const isDropdownActive = () => {
        return location.pathname.startsWith('/uniadmissions');
    }

    const showDropdown = (e) => {
        setShow(true);
        clearTimeout(hideTimeoutRef.current);
    }
    const hideDropdown = e => {
        hideTimeoutRef.current = setTimeout(() => {
            setShow(false);
        }, 200);
    }

    return (
        <Navbar bg="light" data-bs-theme="light" expand="lg" collapseOnSelect style={{ paddingTop: 20, paddingBottom: 20 }}>
            <Container fluid>
                <Navbar.Brand href='/'>
                    <img
                        src={logo}
                        width="60"
                        height="60"
                        className="d-inline-block align-top"
                        alt="ExcellaLearn Logo"
                        style={{ objectFit: 'contain' }}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='navbar-nav' />
                <Navbar.Collapse id='navbar-nav'>
                    <Nav>
                        {pages.map((page, index) => {

                            if (page === 'Uni Admissions') {
                                return (
                                    <NavDropdown title={page}
                                        onMouseEnter={showDropdown}
                                        onMouseLeave={hideDropdown}
                                        show={show}
                                        style={{ marginLeft: 20 }}
                                        active={isDropdownActive()}>
                                        <NavDropdown.Item active={isActive('/uniadmissions')} href={links[index]} >Uni Admissions Home</NavDropdown.Item>
                                        <NavDropdown.Item active={isActive('/uniadmissions/entrance-exams')} href='/uniadmissions/entrance-exams'>Entrance Exams</NavDropdown.Item>
                                        <NavDropdown.Item active={isActive('/uniadmissions/interviews')} href='/uniadmissions/interviews'>Interviews</NavDropdown.Item>
                                        <NavDropdown.Item active={isActive('/uniadmissions/personal-statement')} href='/uniadmissions/personal-statement'>Personal Statement Reviews</NavDropdown.Item>
                                    </NavDropdown>
                                );
                            }

                            return (
                                <Nav.Link active={isActive(links[index])} href={links[index]} style={{ marginLeft: 20 }}>
                                    {page}
                                </Nav.Link>
                            );
                        })}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}